// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EvocativePricingMobile from "./components/EvocativePricingMobile.res.js";
import * as EvocativePricingDesktop from "./components/EvocativePricingDesktop.res.js";
import * as EvocativeBareMetalPricingScss from "./EvocativeBareMetalPricing.scss";

var css = EvocativeBareMetalPricingScss;

function EvocativePricing(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: "Evocative Metal Configurations"
                    }),
                JsxRuntime.jsx(EvocativePricingDesktop.make, {}),
                JsxRuntime.jsx(EvocativePricingMobile.make, {})
              ],
              className: css.wrapper
            });
}

var make = EvocativePricing;

export {
  css ,
  make ,
}
/* css Not a pure module */
