// Generated by ReScript, PLEASE EDIT WITH CARE

import * as HorizontalScroll from "../../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EvocativePricingColumnMobile from "./pricing-column-mobile/EvocativePricingColumnMobile.res.js";
import * as EvocativeBareMetalPricingScss from "../EvocativeBareMetalPricing.scss";

var css = EvocativeBareMetalPricingScss;

function EvocativePricingMobile(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(HorizontalScroll.make, {
                    children: [
                      JsxRuntime.jsx(EvocativePricingColumnMobile.make, {
                            heading: "Small",
                            cpusA: "16",
                            memory: "96 GB",
                            storageA: "2 x 960 GB NVMe",
                            network: "2 x 10 Gbps",
                            bandwidth: "10 TB Included"
                          }),
                      JsxRuntime.jsx(EvocativePricingColumnMobile.make, {
                            heading: "Medium",
                            cpusA: "24",
                            memory: "128 GB",
                            storageA: "2 x 1.94 TB NVMe",
                            network: "2 x 10 Gbps",
                            bandwidth: "10 TB Included"
                          }),
                      JsxRuntime.jsx(EvocativePricingColumnMobile.make, {
                            heading: "Large",
                            cpusA: "32",
                            memory: "512 GB",
                            storageA: "2 x 5.84 TB NVMe",
                            network: "2 x 10 Gbps",
                            bandwidth: "10 TB Included"
                          }),
                      JsxRuntime.jsx(EvocativePricingColumnMobile.make, {
                            heading: "Custom",
                            cpusA: "From 64 or GPU",
                            memory: "From 512 GB",
                            storageA: "Configurable up to 12 Slots",
                            network: "2 x 10 Gbps",
                            bandwidth: "10 TB Included"
                          }),
                      JsxRuntime.jsx("div", {
                            className: css.spacer
                          })
                    ],
                    className: css.horizontalScrollRow
                  }),
              className: css.mobile
            });
}

var make = EvocativePricingMobile;

export {
  css ,
  make ,
}
/* css Not a pure module */
