// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EvocativeBareMetalPricingScss from "../../EvocativeBareMetalPricing.scss";

var css = EvocativeBareMetalPricingScss;

function EvocativePricingColumnMobile(props) {
  var storageB = props.storageB;
  var subheading = props.subheading;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: props.heading
                            }),
                        subheading !== undefined ? JsxRuntime.jsx("div", {
                                children: subheading,
                                className: css.subheading
                              }) : null
                      ],
                      className: Cx.cx([
                            css.cell,
                            css.heading
                          ])
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "CPUs",
                              className: css.mobileOnlyMeta
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.cpusA
                            })
                      ],
                      className: css.cell
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Memory",
                              className: css.mobileOnlyMeta
                            }),
                        props.memory
                      ],
                      className: css.cell
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Storage",
                              className: css.mobileOnlyMeta
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.storageA
                            }),
                        storageB !== undefined ? JsxRuntime.jsx("div", {
                                children: storageB
                              }) : null
                      ],
                      className: css.cell
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Network",
                              className: css.mobileOnlyMeta
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.network
                            })
                      ],
                      className: css.cell
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "DDoS Protection",
                              className: css.mobileOnlyMeta
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.bandwidth
                            })
                      ],
                      className: css.cell
                    })
              ],
              className: Cx.cx([
                    css.column,
                    css.small
                  ])
            });
}

var make = EvocativePricingColumnMobile;

export {
  css ,
  make ,
}
/* css Not a pure module */
