// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as UnderscoreFancyHeader from "../../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancyHeader.res.js";
import * as JumbotronEvocativeScss from "./JumbotronEvocative.scss";
import EvocativeWebp from "../../assets/evocative.webp";

var css = JumbotronEvocativeScss;

var evocativeIcon = EvocativeWebp;

function JumbotronEvocative(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            className: css.icon,
                            alt: "Evocative",
                            src: evocativeIcon
                          }),
                      className: css.logo
                    }),
                JsxRuntime.jsx(H1.make, {
                      className: css.heading,
                      children: "Evocative Metal Servers"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(UnderscoreFancyHeader.make, {
                            children: "Get 1 Month Free!",
                            color: "Orange"
                          }),
                      className: css.subheading
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Orange",
                            expanded: true,
                            onClick: (function (param) {
                                setIsScheduleDemoModalOpen(function (param) {
                                      return true;
                                    });
                              }),
                            children: "Request a Demo"
                          }),
                      className: css.buttonsRow
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Evocative Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about Evocative bare metal solutions.",
                        buttonTitle: "Submit",
                        leadType: "EvocativeBareMetal"
                      }) : null
              ],
              className: css.wrapper
            });
}

var make = JumbotronEvocative;

export {
  css ,
  evocativeIcon ,
  make ,
}
/* css Not a pure module */
