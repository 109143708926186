// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EvocativeBareMetalPricingScss from "../EvocativeBareMetalPricing.scss";

var css = EvocativeBareMetalPricingScss;

function EvocativePricingDesktop(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Small",
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Medium"
                                        }),
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Large"
                                        }),
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "Custom",
                                    className: css.headerCell
                                  })
                            ],
                            className: Cx.cx([
                                  css.row,
                                  css.headerRow
                                ])
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "16"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "24"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "32"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "From 64 or GPU"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "96 GB"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "128 GB"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "512 GB"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "From 512 GB"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 960 GB NVMe"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 1.94 TB NVMe"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 5.84 TB NVMe"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Configurable up to 12 Slots"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 10 Gbps"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 10 Gbps"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 10 Gbps"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 10 Gbps"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Bandwidth",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "10 TB Included"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Bandwidth",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "10 TB Included"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Bandwidth",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "10 TB Included"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Bandwidth",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "10 TB Included"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          })
                    ],
                    className: css.pricingTable
                  }),
              className: css.desktop
            });
}

var make = EvocativePricingDesktop;

export {
  css ,
  make ,
}
/* css Not a pure module */
