// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as EvocativePricing from "./components/pricing/EvocativePricing.res.js";
import * as ExclusiveServices from "../../../../styleguide/components/ExclusiveServices/ExclusiveServices.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EvocativeCaseStudy from "./components/case-study/EvocativeCaseStudy.res.js";
import * as JumbotronEvocative from "./components/jumbotron/JumbotronEvocative.res.js";
import * as ResourcesSidebarSection from "../../../resources/common/sidebar-section/ResourcesSidebarSection.res.js";
import * as EvocativeBareMetalScss from "./EvocativeBareMetal.scss";
import * as EvocativeServicesOverview from "./components/services-overview/EvocativeServicesOverview.res.js";
import * as RequestEstimateEvocativeBareMetal from "./components/request-estimate/RequestEstimateEvocativeBareMetal.res.js";
import EvocativeHeaderLeftSvg from "./assets/evocative-header-left.svg";
import HeaderDotsSvg from "../../common/assets/header-dots.svg";
import EvocativeHeaderRightSvg from "./assets/evocative-header-right.svg";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";

var css = EvocativeBareMetalScss;

var headerDotsSvg = HeaderDotsSvg;

var bareMetalHeaderLeftSvg = EvocativeHeaderLeftSvg;

var bareMetalHeaderRightSvg = EvocativeHeaderRightSvg;

function EvocativeBareMetal$default(props) {
  var caseStudies = props.caseStudies;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronEvocative.make, {}),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + bareMetalHeaderLeftSvg + "), url(" + bareMetalHeaderRightSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(EvocativePricing.make, {}),
                            className: css.pricingAndConfigurationsSectionContainer
                          }),
                      className: css.pricingAndConfigurationsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(RequestEstimateEvocativeBareMetal.make, {}),
                      className: css.requestEstimateBareMetalSection
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(EvocativeServicesOverview.make, {}),
                              className: css.detailsContent
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ExclusiveServices.make, {
                                      title: "Exclusive Services",
                                      services: [
                                        {
                                          service: "Included Data Transfer",
                                          description: "Every server comes with 10 TB bandwidth included. All inbound data transfer is completely free."
                                        },
                                        {
                                          service: "Global Network Backbone",
                                          description: "Leverage our high-speed, private network backbone between our global sites in North America, Europe, and Asia Pacific to reach your end-users where they are."
                                        },
                                        {
                                          service: "Enterprise-Grade Processors",
                                          description: "Our enterprise-grade servers include industry-leading Intel Silver and Gold processors for optimal performance."
                                        },
                                        {
                                          service: "Operating Systems",
                                          description: "Evocative Metal servers can support any operating systems. Use our pre-configured images or Bring Your Own License and get up-and-running quickly."
                                        },
                                        {
                                          service: "IPv4 and IPv6",
                                          description: "Each server comes with one usable IPv4 or IPv6 address, with the option to add more addresses as you’d like."
                                        },
                                        {
                                          service: "Customization Options",
                                          description: "Choose from our pre-built server options or customize your own server to meet your specific requirements. Add RAM, storage, network, and more."
                                        }
                                      ]
                                    }),
                                caseStudies.length !== 0 ? JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(ResourcesSidebarSection.make, {
                                              title: "Resources",
                                              categorySlug: "case-studies",
                                              resources: caseStudies,
                                              query: "evocative"
                                            }),
                                        className: css.sticky
                                      }) : null
                              ],
                              className: css.detailsSidebar
                            })
                      ],
                      className: css.detailsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(ComponentsImportsLoadableJsx.EvocativeBareMetalCloudMap, {}),
                            className: css.map
                          }),
                      className: css.caseStudySection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(EvocativeCaseStudy.make, {
                            evocativeCaseStudy: props.evocativeCaseStudy,
                            userLoginStatus: User.LoginStatus.fromBool(props.isUserLoggedIn),
                            setUserData: props.setUserData
                          }),
                      className: css.caseStudySection
                    })
              ],
              className: css.wrapper
            });
}

var $$default = EvocativeBareMetal$default;

export {
  css ,
  headerDotsSvg ,
  bareMetalHeaderLeftSvg ,
  bareMetalHeaderRightSvg ,
  $$default as default,
}
/* css Not a pure module */
